// import { PlanType } from "../components/pages/VehicleInsurenceComponent/AllLeadsComponent/addJsonData";

export const APIUrlStrings = {
  getQuotation: "/quatation/getQuatation",
  // login: "/user/login",
  // logout: "/logout",
  // productList: "/products/getAll",
  // rolelist: "/roles/getAll",
  // userPermission: "/user-permission",
  // modulePermission: "/user/module-wise-permission",
  // createRole: "/role/create",
};

export const AuthURLStrings = {
  login: "/user/login",
  logout: "/logout",

  getRoleList: "/roles/getAll",
  getUserList: "/user/filter",
  userGetById: "user/getById",
  getTeamLeader: "/myteams/getteamLeader",
  getAllTeam: "teams/getAllTeam",
  getRole: "/myteams/getRole",
  teamName: "myteams/teamName",
  getProduct: "/myteams/getProduct",
  createTeam: "myteams/createTeam",
  userCreate: "/user/create",
  dropdownReportingPerson: "/user/dropdown-reportingPerson",
  roleDropdownList: "role/dropdown-list",
  createRole: "role/create",
  deleteRole: "role/deleteRole",
  userDelete: "user/delete",
  deleteTeam: "myteams/deleteTeam",
  userUpdate: "user/update",
  roleUpdate: "role/update",
  teamUpdate: "myteams/updateTeam",
  countRoleUserTeam: "user/get_user_role_teams_count",
  telecallerListing: "user/get-telecaller-listing",
  modulesAndPermission: "user/get_module_and_permissions",
};

export const LeadURLStrings = {
  leadList: "/lead/getall",
  leadGetId: "/lead/getbyId",
  // leadList:"/lead/getAll",
  leadCreate: "/lead/create",
  leadUpdate: "lead/update",
  leadDelete: "/lead/delete",
  leadCount: "/lead/lead-count",
  leadAssign: "/lead/assign",

  // details Page For Admin
  leadDetailsForAdmin: "lead/lead-page-details",
  quotationDetailsForAdmin: "lead/quotation-details",
  proposalDetailsForAdmin: "lead/proposal-details",
  soldPolicyDetailsForAdmin: "lead/policy-details",

  // download leads
  leadDownload: "/lead/lead-download-listing",
  // lead details edit
  leadEdit: "/lead/edit_lead",
  teamLeaderDropdown: "/lead/teamLeader-dropdown",
  telecallerDropdown: "/lead/telecaller-dropdown",
  policyTypeDropdown: "/lead/lead-policyType/dropDown",
  leadPlanTypeDropdown: "/lead/lead-planType/dropDown",
  leadTypeDropdown: "/lead/lead-type/dropdown",
  leadSourceDropdown: "/leadSource/dropdown",
  getQuotation: "/quatation/getQuatation",
  getProposal: "proposal/getProposal",
  getIdv: "deviation/idv/get",
  getBreakin: "deviation/breakin/get",
  getDiscount: "deviation/discount/get",
  // master-campaign
  getMasterCampaignList: "campaign/getAll",
  createMasterCampaignAdd: "campaign/create",
  getByIdMasterCampaign: "campaign/getById",
  updateMasterCampaign: "campaign/update",
  deleteMasterCampaign: "campaign/delete",
  // master-source
  getMasterSourceList: "leadSource/getAll",
  getByIdMasterSource: "leadSource/getById",
  updateMasterSource: "leadsource/update",
  deleteMasterSource: "leadsource/delete",
  getByIdMasterSourceDropdownList: "publisher/getDropdownList",
  createMasterSourceAdd: "leadSource/Create",
  //master -publisher
  getMasterPublisherList: "publisher/getAll",
  createMasterPublisherAdd: "publisher/create",
  getByIdMasterPublisher: "publisher/getById",
  updateMasterPublisher: "publisher/update",
  deleteMasterPublisher: "publisher/delete",

  // getSoldPolicyList:"policies/getPolicies",
  getLeadDetails: "lead/leadDetails",
  getSoldPolicyList: "policies/getPolicies",
  getDMSList: "lead/dms-get-dmsListing",
  getCommonDetails: "leadProduct-common/details",
  DocumentDelete: "lead/dms-delete",
  dmsDetails: "lead/dms-details",
  // Lost Leads
  getLostLeadList: "lost-leads/getAll",
  getLostLeaadTelecaller: "lead/telecaller-dropdown",
  postLostLeadReAssign: "lead/assign",
  reAssignLostlead: "/lead/assign",

  getdocumentdropdownlist: "lead/dms-group-dropdown",
  getdocumentdropdowntype: "lead/dms-type-dropdown",
  createdocument: "lead/dms-create-upload",
  getDocumentUploadStatusList: "lead/dms-get-list",

  downloadSoldPolicy: "policies/getPolicies",
  downloadQuotation: "quatation/getQuatation",
  downloadPraposal: "proposal/getProposal",
  downloadIDV: "deviation/idv/get",
  downloadDiscount: "deviation/discount/get",
  downloadBreaking: "deviation/breakin/get",

  getDispositionLeadById: "lead/disposition",
  getDispositionLeadDetailsById: "lead/leadDisposition",
  getDispositionDropDown: "lead/disposition-dropdown",
  getAuditorList: "scoredLead",
  getAuditDetails: "auditDetail",
  getCallRecordingDetails: "callRecordingDetail",
  getCallRating: "callRating",

  // bulk upload
  bulkUplodFile: "lead/bulk_Upload",
  formetDownload: "lead/sample_csv",
  downloadInvalidData: "lead/error_csv",
  invalidData: "/lead/preview_bulk_upload",
  materDataDictionary: "lead/download_ddc_csv",

  // telecaller
  telecallerLeadList: "lead/telecaller-lead-listing",
  LeadCountTelecallerWise: "lead/getLeadCountTelecallerWise",
  getQuotationDetails: "lead/get_quotation",
  createDisposition: "lead/add-disposition",
  leadDetailsForTelecaller: "lead/get_details_for_telecaller",
  dialler: "/dialler/make_manual_call",
  sendEmail: "dialler/send-email",

  // dashboard
  // Admin
  productleadList: "/dashboard/count_data",
  planList: "/dashboard/top_plans",
  leadStatistic: "lead/lead-statistics-dashboard",
  productPerformance: "lead/get-product-performance",
  leadSource: "lead/lead-source-dashboard",

  idvCount: "/deviation/idv/count",
  discountCount: "/deviation/discount/count",
  breakinCount: "/deviation/breakin/count",

  // Telecaller
  tellercallerCardData: "/lead/telecaller-dashboard-data",
  actionableInsights: "/lead/actionable-insights-leads-listing",
  leadInformation: "lead/lead-report",
  topPerformance: "lead/top_performers",
  policyStatus: "lead/lead-policy-status",
  downloadLeadDoc: "lead/dms-file",
};
//   getLeadDetails:"lead/leadDetails",
//   getSoldPolicyList:"policies/getPolicies",
//   getDMSList:"lead/dms-get-dmsListing",
//   getCommonDetails:"leadProduct-common/details",
//   getLostLeadList:"lost-leads/getAll",
//   downloadSoldPolicy:"policies/getPolicies",
//   downloadQuotation:"quatation/getQuatation",
//   downloadPraposal:"proposal/getProposal",
//   downloadIDV:"deviation/idv/get",
//   downloadDiscount:"deviation/discount/get",
//   downloadBreaking:"deviation/breakin/get"
// }
