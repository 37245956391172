const ROUTES_NAVIGATION = {
  // DASHBOARD: "/",
  VEHICLE_INSURENCE_DASHBOARD: "/vehicle-insurence/dashboard",

  LOGIN: "/login",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  USER_LIST: "/user_list",

  //VEHICLE INSURENCE
  VEHICLE_INSURENCE: "/vehicle-insurence",
  // VEHICLE_INSURENCE_DASHBOARD: "/vehicle-insurence/dashboard",
  // --------------------Master Data---------------
  VEHICLE_INSURENCE_MASTER_LEAD_SOURCE: "/vehicle-insurence/lead-source",
  // -----------------------------------Lead Manager------------
  VEHICLE_INSURENCE_ALL_LEADS: "/vehicle-insurence/all-leads",
  VEHICLE_INSURENCE_LEAD_DETAILS: "/vehicle-insurence/details",
  VEHICLE_INSURENCE_LEAD_EDIT: "/vehicle-insurence/edit",

  VEHICLE_INSURENCE_QUOTATION_SEND: "/vehicle-insurence/quotation-send",
  VEHICLE_INSURENCE_PRAPOSAL_SEND: "/vehicle-insurence/praposal-send",
  VEHICLE_INSURENCE_PROPOSAL_LEAD: "/vehicle-insurence/proposal-lead",
  VEHICLE_INSURENCE_QUOTATION_LEAD: "/vehicle-insurence/quotation-lead",
  VEHICLE_INSURENCE_SOLD_POLICY: "/vehicle-insurence/sold-policy",
  VEHICLE_INSURENCE_POLICY_DONE_LEAD: "/vehicle-insurence/policy-done-lead",
  VEHICLE_INSURENCE_LOST_LEADS: "/vehicle-insurence/lost-leads",

  VEHICLE_INSURENCE_IDV: "/vehicle-insurence/idv",
  VEHICLE_INSURENCE_DISCOUNT: "/vehicle-insurence/discount",
  VEHICLE_INSURENCE_BREAKING_CASE: "/vehicle-insurence/breaking-case",
  VEHICLE_INSURENCE_USER_AND_ROLE: "/vehicle-insurence/user-roles",
  VEHICLE_INSURENCE_TELE_CALLER_PERFORMANCE:
    "/vehicle-insurence/tele-caller-performance",
  VEHICLE_INSURENCE_SALES_MANAGER_PERFORMANCE:
    "/vehicle-insurence/sales-manager-performance",
  VEHICLE_INSURENCE_DMS: "/vehicle-insurence/dms",
  VEHICLE_INSURENCE_DMS_DETAILS: "/vehicle-insurence/dms-details",
  VEHICLE_INSURENCE_UPLOAD_DATA: "/vehicle-insurence/upload-data",
  VEHICLE_INSURENCE_REPORTS: "/vehicle-insurence/reports",
  VEHICLE_INSURENCE_LOST_CASES: "/vehicle-insurence/lost-cases",
  VEHICLE_INSURENCE_ADDITIONAL_FEATURS:
    "/vehicle-insurence/additional-features",
  VEHICLE_INSURENCE_ADDITIONAL_FEATURS_UPLOAD_NC_DATA:
    "/vehicle-insurence/upload-nc-data",
  VEHICLE_INSURENCE_SETTINGS: "/vehicle-insurence/settings",
  VEHICLE_INSURENCE_AUDITORS_LEADS: "/vehicle-insurence/auditors-leads",
  VEHICLE_INSURENCE_AUDITORS_LEADS_DETAILS:
    "/vehicle-insurence/auditors-leads-details",
  VEHICLE_INSURENCE_TOTALLEAD: "/vehicle-insurence/total-lead",
  VEHICLE_INSURENCE_TRAINING_MARKETING: "/vehicle-insurence/training-marketing",
  VEHICLE_INSURENCE_INBOUND_LEAD: "/vehicle-insurence/inbound-lead",
  VEHICLE_INSURENCE_SALES_MANAGER_MY_TEAMS:
    "/vehicle-insurence/sales-manager-my-teams",
  VEHICLE_INSURENCE_CAMPAIGN_LEAD: "/vehicle-insurence/campaign-lead",
  VEHICLE_INSURENCE_BULK_CAMPAIGN: "/vehicle-insurence/bulk-campaign",
  VEHICLE_INSURENCE_CAMPAIGN_TEMPLATE: "/vehicle-insurence/campaign-template",
  VEHICLE_INSURENCE_CREATE_CAMPAIGN_TEMPLATE:
    "/vehicle-insurence/create-campaign-template",
  VEHICLE_INSURENCE_CAMPAIGN_LEAD_MANAGEMENT:
    "/vehicle-insurence/campaign-leads-details",
  VEHICLE_INSURENCE_RENEWAL_NOTICES: "/vehicle-insurence/renewal-notices",
  VEHICLE_INSURENCE_ACTIONABLE_LEAD: "/vehicle-insurence/actionable-leads",
  VEHICLE_INSURENCE_ACTIONABLE_LEAD_DETAILS:
    "/vehicle-insurence/actionable-leads-details",
  VEHICLE_INSURENCE_CHAT: "/vehicle-insurence/chat",
  //HEALTH INSURENCE
  HEALTH_INSURENCE: "/health-insurence",
  HEALTH_INSURENCE_DASHBOARD: "/health-insurence/dashboard",
};

export default ROUTES_NAVIGATION;
