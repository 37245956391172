// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import userAuthSlice from "./userAuthSlice";
import selectedIdsSlice from "./selectedIdsSlice";
import isEditingSlice from "./isEditingSlice";
// import productmenu from "./productmenu";
// import rolesPermissionMenu from "./rolesPermissionMenu";
// import customerordermenu from "./customerordermenu";
// import addorderitemsmenu from "./addorderitemsmenu";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  userAuthSlice,
  selectedIds :selectedIdsSlice,
  isEditing: isEditingSlice
//   productmenu,
//   rolesPermissionMenu,
//   customerordermenu,
  // addorderitemsmenu,
});

export default reducers;
