import React, { useEffect, useState } from "react";

import "./CustomDateInput.scss";
import ReactDatePicker from "react-datepicker";
import Icon from "@mdi/react";
import { mdiCalendar } from "@mdi/js";

const CustomDateInput = ({
  isDateRange = true,
  dateRange = [],
  setDateRange,
  isClearable = false,
  className = "",
  onChange = () => {},
  placeholderText = "Enter date",
  selected,
  label = "",
  required = false,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  showMonthYearPicker = false,
  dateFormat = "dd-MM-yyyy",
}) => {
  // const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;

  const [startDateTime, setStartDateTime] = useState();
  // setHours(setMinutes(new Date(), 0), 9)

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  // console.log("startDate--->>>>", startDate, endDate);
  return (
    <div className="custom-date-input">
      {label && (
        <div>
          <label
            htmlFor={label}
            className={`select-form-label fw-bold ${
              required ? "required" : ""
            }`}
          >
            {label}
          </label>
        </div>
      )}
      <ReactDatePicker
        selected={selected}
        className={` ${className}`}
        showIcon
        showTimeSelect={showTimeSelect}
        onKeyDown={(e) => e.preventDefault()}
        showTimeSelectOnly={showTimeSelectOnly}
        // timeIntervals={30}
        timeCaption="Next Follow Time"
        // dateFormat="h:mm aa"
        dateFormat={dateFormat}
        toggleCalendarOnIconClick
        selectsRange={isDateRange}
        showMonthYearPicker={showMonthYearPicker}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          console.log("update", update);
          if (isDateRange) {
            setDateRange(update);
          } else {
            onChange(update);
          }
        }}
        isClearable={isClearable}
        placeholderText={placeholderText}
        icon={<Icon path={mdiCalendar} className="" />}
      />
    </div>
  );
};

export default CustomDateInput;
