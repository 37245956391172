import React, { useCallback, useContext, useEffect, useState } from "react";

import MainCard from "../../../shared/MainCard";
import ROUTES_NAVIGATION from "../../../../routes/routes";
import "./LeadManagement.scss"; // Ensure the CSS file is correctly imported
// import Accordion from "react-bootstrap/Accordion";
import PolicyDetailsComponent from "./PolicyDetailsComponent";
import LeadManagmentStatusComponent from "./LeadManagmentStatusComponent";
import LeadManagmentCallStatusComponent from "./LeadManagmentCallStatusComponent";
import CustomButton from "../../../shared/CustomButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import CallDetailsComponent from "./CallDetailsComponent";
import CustomModal from "../../../shared/CustomModal";
import SelectMassageTypeComponent from "./SelectMassageTypeComponent";
import {
  APIDialler,
  APIgetDispositionLeadDetails,
  APIgetleadDetailsForTelecaller,
  APIPolicyStatus,
} from "../../../../api/common";
import GlobalContext from "../../../../context/GlobalContext";
import { hasIn } from "lodash";
const LeadManagementComponent = () => {
  const navigate = useNavigate();
  const [showCallDetails, setShowCallDetails] = useState(false);
  const [isSelectMassageType, setIsSelectMassageType] = useState(false);
  const [leadDisaposition, setLeadDisposition] = useState([]);
  const [leadDispositionForTel, setLeadDispositionForTel] = useState([]);
  const [searchParams] = useSearchParams();
  const [parentRecall, setParentRecall] = useState(false);
  // const [callMessage,setCallMessage] = useState()
  const [hideCallMsgBtn, setHideCallMsgBtn] = useState(false);

  const [statusDetails, setStatusDetails] = useState();

  const { hasAccess } = useContext(GlobalContext);
  const LeadId = leadDisaposition?.leadId;
  const quotation = searchParams.get("details");
  const productId = searchParams.get("productId");
  const leadId = searchParams.get("leadId");
  const role = localStorage.getItem("role");
  // const LeadId = leadDisaposition?.leadId;
  // const quotation = searchParams.get("details");

  const getDispositionDetailsHandler = useCallback(async () => {
    const result = await APIgetDispositionLeadDetails(productId);
    console.log("result******", result?.data?.data);
    if (result?.ok) {
      setLeadDisposition(result?.data?.data);
      // parentRecall();
    } else {
      setLeadDisposition([]);
    }
  }, [productId]);

  const getLeadDetailsForTellecallerHandler = useCallback(async () => {
    const result = await APIgetleadDetailsForTelecaller();
    try {
      if (result?.ok && result?.data?.data) {
        setLeadDispositionForTel(result?.data?.data);
      } else {
        setLeadDispositionForTel([]);
      }
    } catch (error) {
      console.error("Error fetching lead details:", error);
      setLeadDispositionForTel([]);
    }
  }, []);

  // ----------Quoatation Details-----
  //  const getQuotationDetailsHandler = useCallback(async () => {
  //    const result = await API();
  //    try {
  //      if (result?.ok && result?.data?.data) {
  //        setLeadDispositionForTel(result?.data?.data);
  //      } else {
  //        setLeadDispositionForTel([]);
  //      }
  //    } catch (error) {
  //      console.error("Error fetching lead details:", error);
  //      setLeadDispositionForTel([]);
  //    }
  //  }, []);

  const handleTrigger = () => {
    setShowCallDetails(false);
  };

  const handleInitiateCall = async () => {
    setShowCallDetails(true);
    setHideCallMsgBtn(true);

    if (leadId) {
      const resp = await APIDialler(leadId);
      console.log("resp", resp);
    }
  };

  const getStatusList = useCallback(async (leadId) => {
    const resp = await APIPolicyStatus(leadId);
    console.log("resp@@@", resp);
  }, []);

  const handleSaveClick = () => {
    // Toggle the state to trigger the useEffect
    setParentRecall((prev) => !prev);
    // setStatusDetails()
    getStatusList(leadId);
    setHideCallMsgBtn(false);
    console.log("status on the way");
  };

  useEffect(() => {
    getStatusList(leadId);
  }, [leadId, getStatusList]);

  useEffect(() => {
    if (role === "Telecaller") {
      getLeadDetailsForTellecallerHandler();
    }
  }, [getLeadDetailsForTellecallerHandler, role]);

  useEffect(() => {
    if (!showCallDetails) {
      getDispositionDetailsHandler(productId);
    }
  }, [getDispositionDetailsHandler, productId, showCallDetails]);

  const status = leadDisaposition?.isContacted
    ? leadDisaposition?.isQuotationSent
      ? leadDisaposition?.isProposalSent
        ? leadDisaposition?.isPaymentDone
          ? 6
          : 5
        : 4
      : 3
    : 1;

  const getLeadFlagColorFlag = (value) => {
    console.log(value, "!!!");
    let flagColor = "";
    switch (value?.leadType?.toLowerCase()) {
      case "cold":
        flagColor = "text-warning";
        break;
      case "warm":
        flagColor = "text-info";
        break;
      case "hot":
        flagColor = "text-danger";
        break;
      default:
        flagColor = "";
    }
    return flagColor;
  };

  const leadFlagColor = getLeadFlagColorFlag(leadDisaposition);

  return (
    <MainCard title="Leads Details" isBack>
      <div className="card lead-managementMainCard">
        <div className="lead-details-header-sub-container p-3">
          <div className="lead-details-header border-end ">
            <div className="lead-management-header-row">Lead Id</div>
            <div className="lead-management-data-row">
              {leadDisaposition?.leadId}
            </div>
          </div>
          {leadDisaposition?.policyType !== "New" && (
            <div className="lead-details-header border-end ">
              <div className="lead-management-header-row">CRE</div>
              <div className="lead-management-data-row">
                {leadDisaposition?.cre}
              </div>
            </div>
          )}
          <div className="lead-details-header border-end ">
            <div className="lead-management-header-row">Customer</div>
            <div className="lead-management-data-row">
              {leadDisaposition?.leadname}
            </div>
          </div>
          <div className="lead-details-header border-end ">
            <div className="lead-management-header-row">Product Type</div>
            <div className="lead-management-data-row">Two Wheeler</div>
          </div>
          <div className="lead-details-header border-end ">
            <div className="lead-management-header-row">Policy Type</div>
            <div className="lead-management-data-row">
              {leadDisaposition?.policyType}
            </div>
          </div>
          {leadDisaposition?.policyType !== "New" && (
            <div className="lead-details-header border-end ">
              <div className="lead-management-header-row">Policy Status</div>
              <div className="lead-management-data-row">Live</div>
              <div className="lead-management-data-row">Expires in 30 days</div>
            </div>
          )}
          <div className="lead-details-header ">
            <div className="lead-management-header-row">Lead Type</div>
            <div className="lead-management-data-row">
              {leadDisaposition?.leadType}
              <i className={`bi bi-flag-fill ${leadFlagColor}`}></i>
            </div>
          </div>

          <div className="lead-details-header d-flex align-items-center">
            <CustomButton
              // type="button"
              className="custom-button bg-yellow text-blue fw-bold"
              onClick={(row) => {
                // navigate(ROUTES_NAVIGATION.VEHICLE_INSURENCE_LEAD_DETAILS);
                navigate(
                  ROUTES_NAVIGATION.VEHICLE_INSURENCE_DMS_DETAILS +
                    "?id=" +
                    leadDisaposition?.leadId +
                    "&product=" +
                    leadDisaposition?.productId
                );
              }}
            >
              Upload Document
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <PolicyDetailsComponent
            leadDispositionForTel={leadDispositionForTel}
          />
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <LeadManagmentStatusComponent
                  currentStatus={status}
                  options={[
                    { id: 1, label: "Qualified" },
                    { id: 2, label: "Contacted", keys: "isContacted" },
                    { id: 3, label: "Quotations", keys: "isQuotationSent" },
                    { id: 4, label: "Proposal Sent", keys: "isProposalSent" },
                    { id: 5, label: "Payment", keys: "isPaymentDone" },
                    { id: 6, label: "Converted", keys: "isPaymentDone" },
                  ]}
                />
              </div>
            </div>
            {/* {showCallDetails ? (
              <div className="col-md-12 mt-3">
                <div className="call-details mb-3 d-flex gap-3 p-3">
                  <div className="">Call Details</div>
                </div>
                <div className="card bg-body-seconary ">
                  <div className="card-body">
                    <CallDetailsComponent />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-end mt-3">
                  <div className="lead-details-header d-flex align-items-center">
                    <CustomButton
                      // type="button"
                      className="send-massage-btn bg-yellow text-blue fw-bold me-2"
                      onClick={() => setIsSelectMassageType(true)}
                    >
                      <i className="bi-card-text me-2" />
                      Send Message
                    </CustomButton>
                  </div>
                  <div className="lead-details-header d-flex align-items-center">
                    <CustomButton
                      // type="button"
                      className="initiate-call-btn bg-yellow text-blue fw-bold"
                      onClick={handleInitiateCall}
                    >
                      <i class="bi bi-telephone me-2" />
                      Initiate a Call
                    </CustomButton>
                  </div>
                </div>

                <div className="col-md-12 mt-3">
                  <div className="card bg-body-seconary ">
                    <div className="card-body">
                      <LeadManagmentCallStatusComponent />
                    </div>
                  </div>
                </div>
              </>
              // <div className="col-md-12 mt-3">
              //   <div className="call-details mb-3 d-flex gap-3 p-3">
              //     <div className="">Call Details</div>
              //   </div>
              //   <div className="card bg-body-seconary ">
              //     <div className="card-body">
              //       <CallDetailsComponent />
              //     </div>
              //   </div>
              // </div>
            )} */}
            {/* {showCallDetails ? ( */}
            <div>
              {!hideCallMsgBtn ? (
                <div className="d-flex justify-content-end mt-3">
                  {hasAccess("HAS_LM_SEND_MESSAGES") && (
                    <div className="lead-details-header d-flex align-items-center">
                      <CustomButton
                        // type="button"
                        className="send-massage-btn bg-yellow text-blue fw-bold me-2"
                        onClick={() => setIsSelectMassageType(true)}
                      >
                        <i className="bi-card-text me-2" />
                        Send Message
                      </CustomButton>
                    </div>
                  )}
                  {hasAccess("HAS_LM_INITITATE_CALL") && (
                    <div className="lead-details-header d-flex align-items-center">
                      <CustomButton
                        // type="button"
                        className="initiate-call-btn bg-yellow text-blue fw-bold"
                        onClick={handleInitiateCall}
                        // onClick={() => setShowCallDetails(true)}
                      >
                        <i class="bi bi-telephone me-2" />
                        Initiate a Call
                      </CustomButton>
                    </div>
                  )}
                </div>
              ) : null}
              {/* <div className="d-flex justify-content-end mt-3">
                {hasAccess("HAS_LM_SEND_MESSAGES") && (
                  <div className="lead-details-header d-flex align-items-center">
                    <CustomButton
                      // type="button"
                      className="send-massage-btn bg-yellow text-blue fw-bold me-2"
                      onClick={() => setIsSelectMassageType(true)}
                    >
                      <i className="bi-card-text me-2" />
                      Send Message
                    </CustomButton>
                  </div>
                )}
                {hasAccess("HAS_LM_INITITATE_CALL") && (
                  <div className="lead-details-header d-flex align-items-center">
                    <CustomButton
                      // type="button"
                      className="initiate-call-btn bg-yellow text-blue fw-bold"
                      onClick={handleInitiateCall}
                      // onClick={() => setShowCallDetails(true)}
                    >
                      <i class="bi bi-telephone me-2" />
                      Initiate a Call
                    </CustomButton>
                  </div>
                )}
              </div> */}
              {showCallDetails && (
                <div className="col-md-12 mt-3">
                  <div className="call-details mb-3 d-flex gap-3 p-3">
                    <div className="">Call Details</div>
                  </div>
                  <div className="card bg-body-seconary ">
                    <div className="card-body">
                      <CallDetailsComponent
                        onTrigger={handleTrigger}
                        // renderTrigger={handleSaveClick}
                        handleSaveClick={handleSaveClick}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12 mt-3">
                <div className="card bg-body-seconary ">
                  <div className="card-body">
                    {console.log("-->>showCallDetails", showCallDetails)}
                    <LeadManagmentCallStatusComponent
                      showCallDetails={showCallDetails}
                      handleSaveClick={handleSaveClick}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ) : (
              <>
                <div>
                  <div className="d-flex justify-content-end mt-3">
                    <div className="lead-details-header d-flex align-items-center">
                      <CustomButton
                        // type="button"
                        className="send-massage-btn bg-yellow text-blue fw-bold me-2"
                        onClick={() => setIsSelectMassageType(true)}
                      >
                        <i className="bi-card-text me-2" />
                        Send Message
                      </CustomButton>
                    </div>
                    <div className="lead-details-header d-flex align-items-center">
                      <CustomButton
                        // type="button"
                        className="initiate-call-btn bg-yellow text-blue fw-bold"
                        onClick={handleInitiateCall}
                      >
                        <i class="bi bi-telephone me-2" />
                        Initiate a Call
                      </CustomButton>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="card bg-body-seconary ">
                      <div className="card-body">
                        <LeadManagmentCallStatusComponent
                          trigger={saveTriger}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )} */}
          </div>
        </div>
      </div>
      <CustomModal
        show={isSelectMassageType}
        title="Select Message Type"
        handleClose={() => setIsSelectMassageType(false)}
        handleSave={() => setIsSelectMassageType(false)}
      >
        <SelectMassageTypeComponent
          onCancel={() => setIsSelectMassageType(false)}
          onSave={() => setIsSelectMassageType(false)}
        />
      </CustomModal>
    </MainCard>
  );
};

export default LeadManagementComponent;
