const settings = {
  // AUTH_API_BASE_URL: "http://103.193.75.11:9090/qcrm/auth/v1", //production Auth
  // AUTH_API_BASE_URL: "http://185.131.55.137:9090/qcrm/auth/v1", //UAT Auth
  // AUTH_API_BASE_URL: "http://198.168.0.146:7778/qcrm/auth/v1",  // Akshay
  // AUTH_API_BASE_URL: "http://localhost:6677/qcrm/auth/v1",
  AUTH_API_BASE_URL: "https://api.qcrm.quantique.ai/qcrm/auth/v1", //main auth production

  // L_BASE_URL: "http://103.193.75.11:9191/qcrm/leads/v1", //production Lead
  // LEAD_API_BASE_URL: "http://185.131.55.137:9191/qcrm/leads/v1", //UAT Lead
  // LEAD_API_BASE_URL: "http://198.168.0.146:7777/qcrm/leads/v1", //Akshay
  // LEAD_API_BASE_URL: "http://localhost:6777/qcrm/leads/v1",
  LEAD_API_BASE_URL: "https://api.qcrm.quantique.ai/qcrm/leads/v1", //main lead production
};
export default settings;
