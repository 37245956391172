import React, { useCallback, useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./PolicyDetailsComponent.scss";
import CustomTab from "../../../../shared/CustomTab";
import {
  APIgetLeadDetailForAdmin,
  APIgetLeadDetails,
  APIgetProposalDetailForAdmin,
  APIgetQuotation,
  APIgetQuotationDetailForAdmin,
  APIgetSoldPolicyDetailForAdmin,
} from "../../../../../api/common";
import GlobalContext from "../../../../../context/GlobalContext";
import { useSearchParams } from "react-router-dom";
import CustomButton from "../../../../shared/CustomButton";
import { Icon } from "@mui/material";
import { mdiPencilOutline } from "@mdi/js";
const TAB_LIST = [
  { id: "details", label: "Details", icon: "bi-card-text" },
  { id: "recommendedplans", label: "Recommended Plans", icon: "bi-card-text" },
];
const PolicyDetailsComponent = ({ leadDispositionForTel }) => {
  const [activeTab, setActiveTab] = useState(TAB_LIST[0]);
  const [selectedOptions, setSelectedOptions] = useState(["Paid Driver Cover"]);
  const [quotationData, setQuotationDatat] = useState([]);
  const [leadMainData, setLeadMainData] = useState([]);
  const [searchParams] = useSearchParams();
  const [detailsForAdmin, setDetailsForAdmin] = useState([]);
  // const LeadId = searchParams.get("id");
  const leadId = searchParams.get("leadId");
  const productId = searchParams.get("productId");
  const baseParams = searchParams.get("details");
  console.log(":D", baseParams);
  const { hasAccess } = useContext(GlobalContext);
  const role = localStorage.getItem("role");
  console.log("***", leadId);
  console.log("detailsForAdmin", detailsForAdmin);

  const getAPILeadDetailsHandeler = useCallback(async (id) => {
    const resp = await APIgetLeadDetails(id);
    console.log("Details resp", resp);
    if (resp?.ok) {
      setLeadMainData(resp?.data?.data?.mainData);
    }
  }, []);

  const getLeadDetailsForAddmin = async (leadId) => {
    console.log("productId123", productId);
    const respone = await APIgetLeadDetailForAdmin(leadId);
    console.log("respone<><>", respone?.data?.data);
    if (respone?.ok) {
      setDetailsForAdmin(respone?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };
  // Quotation details send
  const getQuotationDetailsForAddminHandler = async (leadId) => {
    const respone = await APIgetQuotationDetailForAdmin(leadId);
    console.log("getQuotationDetailsForAddmin", respone?.data?.data);
    if (respone?.ok) {
      setDetailsForAdmin(respone?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  // Proposal details send
  const getProposalDetailsForAddminhandler = async (leadId) => {
    console.log("getProposalDetailsForAddminhandler Id", leadId);
    const response = await APIgetProposalDetailForAdmin(leadId);
    console.log("APIgetProposalDetailForAdmin", response?.data?.data);
    if (response?.ok) {
      setDetailsForAdmin(response?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  const getSoldPolicyDetailsForAddminhandler = async (leadId) => {
    const response = await APIgetSoldPolicyDetailForAdmin(leadId);
    console.log("getSoldPolicyDetailsForAddminhandler", response?.data?.data);
    if (response?.ok) {
      setDetailsForAdmin(response?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  const getQuotationHandler = useCallback(async () => {
    const resp = await APIgetQuotation();
    console.log("quotation", resp);
    if (resp?.ok === true) {
      setQuotationDatat(resp?.data?.data);
    } else {
      setQuotationDatat([]);
    }
  }, []);

  const handleCheckboxChange = (label) => {
    setSelectedOptions((prev) =>
      prev.includes(label)
        ? prev.filter((item) => item !== label)
        : [...prev, label]
    );
  };
  useEffect(() => {
    if (leadId) {
      getAPILeadDetailsHandeler(leadId);
    }
  }, [leadId, getAPILeadDetailsHandeler]);
  // HAS_UAR_USERS_VIEW_DETAILS
  useEffect(() => {
    if (role === "Telecaller") {
      getQuotationHandler();
    }
  }, [getQuotationHandler, role]);

  useEffect(() => {
    if (baseParams === "leadDetails") {
      getLeadDetailsForAddmin(leadId);
      console.log("Details of Admin");
    }
  }, [baseParams, leadId]);

  useEffect(() => {
    if (baseParams === "quotation") {
      getQuotationDetailsForAddminHandler(leadId);
    }
  }, [baseParams, leadId]);

  useEffect(() => {
    if (baseParams === "praposal") {
      getProposalDetailsForAddminhandler(leadId);
    }
  }, [baseParams, leadId]);

  useEffect(() => {
    if (baseParams === "sold") {
      getSoldPolicyDetailsForAddminhandler(leadId);
    }
  }, [baseParams, leadId]);

  return (
    <div>
      <div className="card pt-3">
        {hasAccess("HAS_LM_VIEW_LEADS_DETAILS_QUOTATION") && (
          <div>
            <CustomTab
              tabList={TAB_LIST}
              onClick={(tab) => {
                setActiveTab(tab);
              }}
              style={{ width: "8rem" }}
              activeTab={activeTab}
            />
            <div className="card-body policydetails-Main-div">
              {activeTab?.id === "details" && (
                <Accordion defaultActiveKey="0" flush>
                  {/* {POLICY_DETAILS?.map((value, index) => { */}
                  {/* {leadDispositionForTel?.map((value, index) => { */}
                  {detailsForAdmin?.map((value, index) => {
                    return (
                      <Accordion.Item
                        eventKey={index}
                        className="custom-accordion"
                      >
                        <Accordion.Header className="custom-accordion-header">
                          {value?.title}
                        </Accordion.Header>
                        <Accordion.Body className="custom-accordion-body ">
                          <div className="policydetails">
                            {value?.fields?.map((data) => {
                              return (
                                <div className="policydetails-text-main-container">
                                  <div className="policydetails-text-container">
                                    <div className="policydetails-text-rightside">
                                      {data?.label}
                                    </div>
                                    <div className="policydetails-text-leftside">
                                      {data?.value}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              )}
              {activeTab?.id === "recommendedplans" &&
                //  (
                //   <div className="recommended-plan">
                //     <div className="card p-3 mb-3">
                //       <div className="d-flex justify-content-between align-items-center mb-3">
                //         <div className="d-flex">
                //           <img
                //             src={PlanLogo} // Replace with actual image URL
                //             alt="Tata AIG Logo"
                //             style={{ width: "50px" }}
                //           />
                //           <div className="ms-3">
                //             <h5 className="mb-0">Tata AIG Premium</h5>
                //             <small>Third Party</small>
                //           </div>
                //         </div>
                //         <div>
                //           <h4 className="price-tag text-primary mb-0">₹ 2,240</h4>
                //         </div>
                //       </div>
                //       <div className="mb-3 mt-3">
                //         {[
                //           "Owner-Driver PA Cover",
                //           "Paid Driver Cover",
                //           "Rs. 1 Lac Unnamed Passenger Cover",
                //           "Rs. 2 Lac Unnamed Passenger Cover",
                //           "Bi-Fuel Kit Cover",
                //           "Electrical Accessories",
                //           "Non-Electrical Accessories",
                //         ].map((option) => (
                //           <div className="form-check" key={option}>
                //             <input
                //               className="form-check-input"
                //               type="checkbox"
                //               id={option}
                //               checked={selectedOptions.includes(option)}
                //               onChange={() => handleCheckboxChange(option)}
                //             />
                //             <label className="form-check-label" htmlFor={option}>
                //               {option}
                //             </label>
                //           </div>
                //         ))}
                //       </div>
                //       <button className="btn insurance-portal-btn w-100">
                //         Insurance Portal &rarr;
                //       </button>
                //     </div>
                //   </div>
                // )
                quotationData.map((value) => (
                  <div className="recommended-plan">
                    <div className="card p-3 mb-3">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex">
                          <img
                            src="https://unlistedzone.com/storage/company-logo/396/sbi_general_insurance_unlisted_shares-logo-1704885685.png"
                            alt="Tata AIG Logo"
                            style={{ width: "50px" }}
                          />
                          <div className="ms-3">
                            <h5 className="mb-0">{value?.icName}</h5>
                            <small>{value?.icDes}</small>
                          </div>
                        </div>
                        <div>
                          <h4 className="price-tag text-primary mb-0">
                            ₹{value?.premium}
                          </h4>
                        </div>
                      </div>
                      <div className="mb-3 mt-3">
                        {(value?.coverList).map((option) => (
                          <div className="form-check" key={option.Id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={option.Id}
                              checked={selectedOptions.includes(option)}
                              onChange={() => handleCheckboxChange(option)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={option}
                            >
                              {option?.label}
                            </label>
                          </div>
                        ))}
                      </div>
                      <button
                        className="btn insurance-portal-btn w-100"
                        onClick={() =>
                          (window.location.href = value?.buttonData?.link)
                        }
                      >
                        {value?.buttonData?.title}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {hasAccess("HAS_UAR_USERS_VIEW_DETAILS") && (
          <div>
            <div className="d-flex mt-2 headline-details">
              <i class="bi bi-card-text" />
              <div className="ms-2 mb-2">Details</div>
              {/* <span className="header-according-action">
                <CustomButton
                  tooltip={"Edit"}
                  className={"rounded-btn text-dark-teal bg-gray p-0"}
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleToggle();
                    // setOpenInsuForm(true);
                  }}
                >
                  <Icon path={mdiPencilOutline} size={1} />
                </CustomButton>
              </span> */}
            </div>
            <hr />
            <div className="card-body policydetails-Main-div">
              <Accordion defaultActiveKey="0" flush>
                {/* {leadMainData?.map((value, index) => { */}

                {detailsForAdmin?.map((value, index) => {
                  return (
                    <Accordion.Item
                      eventKey={index}
                      className="custom-accordion "
                    >
                      <Accordion.Header className="custom-accordion-header">
                        {value?.title}
                      </Accordion.Header>
                      <Accordion.Body className="custom-accordion-body ">
                        <div className="policydetails">
                          {value?.fields?.map((data) => {
                            return (
                              <div className="policydetails-text-main-container">
                                <div className="policydetails-text-container">
                                  <div className="policydetails-text-rightside">
                                    {data?.label}
                                  </div>
                                  <div className="policydetails-text-leftside">
                                    {data?.value}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        )}
      </div>

      {/* --------------Accordian----------- */}
    </div>
  );
};

export default PolicyDetailsComponent;
